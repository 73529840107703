<template>
  <div>
    <!-- Title page -->
    <div class="mx-auto mb-2">
      <b-row class="justify-content-between align-items-center">
        <b-col md="4">
          <h1 class="text-primary font-weight-bolder text-uppercase ml-1 my-0">Modèles de mails</h1>
        </b-col>
        <b-col md="4" offset-md="4" class="text-md-right text-sm-center">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            v-b-toggle.sidebar-model
            @click="
              modalAction = 'Nouveau';
              modelChoosed = { id: null, type_activite: null, modele: null, objet: null, corps_mail: null, courtier_id: currentUser.courtier_user[0].courtier_id }
            "
            v-if="can('Create modele')"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Nouveau modèle</span>
          </b-button>
        </b-col>
      </b-row>

      <actions-bibliotheque-mail-vue v-if="renderComponent" :courtier-id="currentUser.courtier_user[0].courtier_id" :modal-action="modalAction" :model-choosed="modelChoosed" @update-data-after-submit="updateDataAfterSubmit" />
    </div>

    <b-overlay :show="showLoading" no-wrap />
    <vue-good-table
      v-if="can('List modeles')"
      :columns="bibliothequeMailColumns"
      :rows="rowsBibliothequeMail"
      :rtl="direction"
      style-class="vgt-table condensed"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: pageLength,
        rowsPerPageLabel: '',
        dropdownAllowAll: false,
        perPageDropdownEnabled: false,
        nextLabel: 'Suivant',
        prevLabel: 'Précédent',
        ofLabel: 'de',
        infoFn: params => ``
      }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'type_activite'">
          {{ typeActiviteOptions.find(item => item.value === props.row.type_activite).text }}
        </span>
        <span v-else-if="props.column.field === 'actions'">
          <span>
            <b-dropdown variant="link" toggle-class="text-decoration-none p-0" no-caret>
              <template v-slot:button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
              </template>
              <b-dropdown-item v-if="can('Visualize modele')" @click="openModal('Visualiser', props.row)">
                <feather-icon icon="SearchIcon" class="mr-50" />
                <span>Visualiser</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="can('Update modele')" @click="openModal('Modifier', props.row)">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span>Éditer</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="can('Delete modele')" @click="removeModele(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Supprimer</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <b-row class="mt-2 align-items-center">
              <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> de {{ props.total }} entrées </span>
                </div>
              </b-col>
          </b-row>
        <b-row class="mt-2 align-items-center">
          <b-col>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </template>
      <div slot="emptystate" class="text-center align-middle">
        <span>Aucune donn&eacute;e disponible dans le tableau</span>
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import { BPagination, VBToggle, BRow, BCol, BDropdown, BDropdownItem, BButton, BCard, BCardText, BCardHeader, BMedia, BImg, BOverlay, BFormSelect } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import ActionsBibliothequeMailVue from './ActionsBibliothequeMail.vue'

export default {
  components: {
    VBToggle,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    BCardHeader,
    BMedia,
    BImg,
    BOverlay,
    VueGoodTable,
    BPagination,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,

    // UI
    ActionsBibliothequeMailVue
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  data() {
    return {
      typeActiviteOptions: [
        { value: 'MESSAGE', text: 'Message' },
        { value: 'TACHE', text: 'Tâche' },
        { value: 'RENDEZVOUS', text: 'Rendez-vous' },
        { value: 'ECHEANCE', text: 'Échéance' },
        { value: 'RAPPEL', text: 'Rappel' },
        { value: 'NOTES', text: 'Notes' }
      ],
      showLoading: false,
      pageLength: 3,
      dir: false,
      renderComponent: true,
      currentUser: JSON.parse(localStorage.getItem('userData')),
      modelChoosed: { id: null, type_activite: null, modele: null, objet: null, corps_mail: null, courtier_id: null },
      modalAction: 'Nouveau',
      rowsBibliothequeMail: [],
      bibliothequeMailColumns: [
        // { label: 'Activité', field: 'type_activite', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
        { label: 'Nom du modèle', field: 'modele', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
        { label: 'Objet/Titre du mail', field: 'objet', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
        { label: 'Actions', field: 'actions', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' }
      ]
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  mounted() {
    if(this.$route.params.showAddModel)   
      this.$root.$emit('bv::toggle::collapse', 'sidebar-model')
  },
  created() {
    this.get_models_by_courtier()
  },
  methods: {
    forceRerender() {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant
        }
      })
    },
    removeModele(id) {
      this.$swal({
        title: 'Etes-vous sûr de vouloir supprimer le modèle ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(confirmed => {
        if (confirmed.value) {
          this.$http
            .delete(`/bibliotheque_mail/modele/${id}`)
            .then(response => {
              if (response.data.success) {
                this.rowsBibliothequeMail = this.rowsBibliothequeMail.filter(g => {
                  return id !== g.id
                })
                this.messageToast('Le modèle de mail a été supprimé avec succès.', 'Succès', 'success')
              }
            })
            .catch(err => {
              this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
              console.log(err)
            })
        }
      })
    },

    openModal(action, modelArray) {
      if (action !== 'Nouveau') {
        this.modelChoosed = modelArray
      } else {
        this.modelChoosed.id = null
        this.modelChoosed.type_activite = null
        this.modelChoosed.modele = null
        this.modelChoosed.objet = null
        this.modelChoosed.corps_mail = null
        this.modelChoosed.courtier_id = this.currentUser.courtier_user[0].courtier_id
      }
      this.modalAction = action
      this.$root.$emit('bv::toggle::collapse', 'sidebar-model')
    },
    updateDataAfterSubmit(data, message) {
      if (data.is_created) {
        this.rowsBibliothequeMail.unshift(data.data)
        this.messageToast(message, 'Succès', 'success')
      } else {
        this.modelChoosed.id = data.data.id
        this.modelChoosed.type_activite = data.data.type_activite
        this.modelChoosed.modele = data.data.modele
        this.modelChoosed.objet = data.data.objet
        this.modelChoosed.corps_mail = data.data.corps_mail
        this.modelChoosed.courtier_id = data.data.courtier_id
        this.messageToast(message, 'Succès', 'success')
      }
    },
    get_models_by_courtier() {
      this.showLoading = true
      this.$http
        .get(`/bibliotheque_mail/${this.currentUser.courtier_user[0].courtier_id}`)
        .then(res => {
          this.rowsBibliothequeMail = res.data.data
          this.showLoading = false
        })
        .catch(err => {
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          console.error(err)
          this.showLoading = false
        })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
