<template>
  <div>
    <b-sidebar id="sidebar-model" bg-variant="white" sidebar-class="sidebar-lg" ref="sideBarModel" shadow backdrop no-header right>
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">{{ modalAction }} modèle</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>

        <validation-observer ref="modeleComposeRules">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

            <b-overlay :show="showLoading" no-wrap />
            <!-- <div> -->
            <!-- <b-form-group label="Activité *" label-for="activite">
              <validation-provider #default="{ errors }" name="activité" rules="required">
                <b-form-select :disabled="modalAction === 'Visualiser'" v-model="composeData.type_activite" :options="typeActiviteOptions" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group> -->

            <b-form-group label="Nom du modèle *" label-for="modele">
              <validation-provider #default="{ errors }" name="modèle" rules="required">
                <b-form-input :disabled="modalAction === 'Visualiser'" id="nom" v-model="composeData.modele" placeholder="Nom du modèle" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Objet" label-for="objet">
              <validation-provider #default="{ errors }" name="objet" rules="">
                <b-form-input :disabled="modalAction === 'Visualiser'" id="nom" v-model="composeData.objet" placeholder="objet/titre du mail" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <validation-provider #default="{ errors }" name="message" rules="required">
              <div style="height: 250px;" class="message-editor" :state="errors.length > 0 ? false : null">
                <quill-editor style="height: 53%;"  :disabled="modalAction === 'Visualiser'" v-model="composeData.corps_mail" :options="snowOption" :state="errors.length > 0 ? false : null" />
              </div>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
            </validation-provider>

            <div class="d-flex mt-2" v-if="modalAction !== 'Visualiser'">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="recordModel"> Enregistrer </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BButton, BOverlay, BSidebar, VBToggle, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BFormSelect } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
import colorPicker from '../../messageries/ColorPicker'

export default {
  components: {
    quillEditor,
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BFormSelect,
    BOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    courtierId: {
      type: Number,
      default: null,
      required: true
    },
    modelChoosed: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        id: null,
        type_activite: null,
        modele: null,
        objet: null,
        corps_mail: null,
        courtier_id: null
      },
      required: true
    },
    modalAction: {
      type: String,
      default: 'Nouveau',
      required: true
    }
  },
  data() {
    return {
      showLoading: false,
      renderComponent: true,
      required,
      typeActiviteOptions: [
        { value: 'MESSAGE', text: 'Message' },
        { value: 'TACHE', text: 'Tâche' },
        { value: 'RENDEZVOUS', text: 'Rendez-vous' },
        { value: 'ECHEANCE', text: 'Échéance' },
        { value: 'RAPPEL', text: 'Rappel' },
        { value: 'NOTES', text: 'Notes' }
      ],
      snowOption: {
        theme: 'snow',
        placeholder: 'Rédiger du texte ici... *'
      },
      composeData: {
        id: null,
        type_activite: 'MESSAGE',
        modele: null,
        objet: null,
        corps_mail: null,
        courtier_id: null
      }
    }
  },
  watch: {
    modalAction: {
      immediate: true,
      deep: true,
      handler() {
        this.getDataFromParent()
      }
    },
    modelChoosed: {
      immediate: true,
      deep: true,
      handler() {
        this.getDataFromParent()
      }
    }
  },
  created() {
    this.composeData.id = null
    // this.composeData.type_activite = null
    this.composeData.type_activite = 'MESSAGE'
    this.composeData.modele = null
    this.composeData.objet = null
    this.composeData.corps_mail = null
    this.composeData.courtier_id = this.courtierId
  },
  methods: {
    getDataFromParent() {
      if (this.modelChoosed === 'Nouveau') {
        this.composeData.id = null
        this.composeData.type_activite = 'MESSAGE'
        // this.composeData.type_activite = null
        this.composeData.modele = null
        this.composeData.objet = null
        this.composeData.corps_mail = null
        this.composeData.courtier_id = this.courtierId

        this.$refs.modeleComposeRules.reset()
      } else {
        this.composeData.id = this.modelChoosed.id
        this.composeData.type_activite = 'MESSAGE'
        // this.composeData.type_activite = this.modelChoosed.type_activite
        this.composeData.modele = this.modelChoosed.modele
        this.composeData.objet = this.modelChoosed.objet
        this.composeData.corps_mail = this.modelChoosed.corps_mail
        this.composeData.courtier_id = this.modelChoosed.courtier_id
      }
    },
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant
        }
      })
    },
    recordModel() {
      this.$refs.modeleComposeRules.validate().then(success => {
        if (success) {
          this.showLoading = true
          this.$http
            .post('/bibliotheque_mail/modele', this.composeData)
            .then(res => {
              if (res.data.success) {
                this.modelChoosedLocal = res.data.data
                this.showLoading = false
                const message = this.composeData.id ? 'Les modifications ont été faites avec succès.' : 'Le modèle a été créé avec succès.'
                this.updateDataAfterSubmit(res.data.data, message)
                this.$refs.sideBarModel.hide()
              }
            })
            .catch(err => {
              this.showLoading = false
              this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
              console.log(err)
            })
        }
      })
    },
    forceRerender() {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    updateDataAfterSubmit(data, message) {
      this.$emit('update-data-after-submit', data, message)
    },
    closeModal() {
      this.$emit('closeModal')
    }
  },
  setup() {
    const colors = ref([])
    colors.value = colorPicker.colors
    return {
      colors
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
